<template>
  <div class="flex-container">
    <div class="flex-child inner-left">
      <masthead class="masthead-title" title="Go mobile!" :show-search="false" />
      <p v-if="mobileAppType === 'virtual-phone'" class="copy">
        {{ mobilePhoneAccessCopyText1 }}
      </p>
      <p v-if="mobileAppType === 'corporate-tools'" class="copy">
        {{ mobileCTAccessCopyText1 }}
      </p>
      <p class="copy">
        {{ mobileAccessCopyText2 }}
      </p>
      <div class="toggle-button">
        <span v-if="!loading">
          <span
            class="toggle-wrapper"
            role="checkbox"
            :aria-checked="mobileAccessChoice.toString()"
            tabindex="0"
            @click="toggle"
            @keydown.space.prevent="toggle"
          >
            <span
              class="toggle-background"
              :class="backgroundStyles"
            />
            <span
              :class="isModal ? 'modal-toggle-indicator': 'toggle-indicator'"
              :style="indicatorStyles"
            />
          </span>
          <p>Allow Mobile Access</p>
        </span>
        <ct-centered-spinner v-else />
      </div>
    </div>
    <div v-if="mobileAccessChoice" class="flex-child inner-right">
      <div v-if="!usernameExist" class="inner-content">
        <p class="inner-content-title">
          Create a Username
        </p>
        <div class="inner-content-sub-title-content">
          <p class="inner-content-sub-title-content-label">
            Mobile App username
          </p>
          <validation-provider v-slot="v" rules="min|max">
            <span class="errorMessage">{{ v.errors[0] }}</span>
            <b-form-input
              id="username-input"
              v-model="newUsername"
              size="sm"
              :state="isValid"
              placeholder="Enter your username"
            />
          </validation-provider>
          <b-button class="confirmButton" :disabled="!isValid" variant="info" @click="canCreateOrUpdate">
            {{ !toUpdateUsername ? "Confirm" : "Update" }}
          </b-button>
          <div v-if="suggestions.length > 0" class="username-state">
            <p class="username-state-title">
              Suggestions
            </p>
            <div v-for="name in suggestions" :key="name" class="username-state-suggestions">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="usernameExist" class="inner-content">
        <p class="inner-content-title">
          Mobile App Login
        </p>
        <div class="inner-content-sub-title-content">
          <div class="inner-content-display-username">
            Username:
            <span id="username-highlight">
              {{ username }}
              <a href="#" @click="editClicked($event)">
                <b-icon-pencil />
                Edit
              </a>
            </span>
          </div>
        </div>
        <p :class="{ 'modal-text-size': isModal }">
          The mobile app password shares the same password as your web login (what you use to log in to this account)
        </p>
        <div class="flex-container qrcode">
          <div class="flex-child qrcode-description">
            <h5>iPhone</h5>
            <p :class="{ 'modal-text-size': isModal }">
              Please Scan with your phone's camera app or click to access the iOS application
            </p>
          </div>
          <div class="flex-child">
            <b-link :href="iosTokenLink">
              <vue-qrcode :value="iosTokenLink" :options="{ width: 200 }" />
            </b-link>
          </div>
        </div>
        <div class="flex-container qrcode">
          <div class="flex-child qrcode-description">
            <h5>Android</h5>
            <p :class="{ 'modal-text-size': isModal }">
              Please Scan with your phone's camera app or click to access the Android application
            </p>
          </div>
          <div class="flex-child">
            <b-link :href="androidTokenLink">
              <vue-qrcode :value="androidTokenLink" :options="{ width: 200 }" />
            </b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, extend } from 'vee-validate'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

extend('min', value => {
  if (value.length >= 6) {
    return true
  }
  return 'Username is too short. It must contain at least 6 characters'
})

extend('max', value => {
  if (value.length <= 32) {
    return true
  }
  return 'Username is too long. It cannot exceed 32 characters'
})
export default {
  name: 'MobileAccess',
  components: { Masthead, ValidationProvider, VueQrcode, CtCenteredSpinner },
  props: {
    token: {
      type: String,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    mobileAppType: {
      type: String,
      default: 'virtual-phone',
    },
  },
  data() {
    return {
      loading: true,
      mobileAccessChoice: false,
      newUsername: '',
      usernameExist: false,
      suggestions: [],
      isValid: false,
      toUpdateUsername: false,
      iOSPhoneTokenLink: 'https://apps.apple.com/us/app/corporate-phone/id1593053186',
      iOSCTTokenLink: 'https://apps.apple.com/us/app/corporate-tools/id1565428192',
      androidPhoneTokenLink: 'https://play.google.com/store/apps/details?id=com.corporatetools.phoneservice',
      androidCTTokenLink: 'https://play.google.com/store/apps/details?id=com.corporatetools.corporatetools',
      mobilePhoneAccessCopyText1: ` Take Phone Service everywhere you go, with the Corporate Phone mobile app.
          To get started, scan the QR code, download the app, and log in with your
          email address and existing client password.`,
      mobileCTAccessCopyText1: `Empower your business with the Corporate Tools app, the ultimate solution
      for document management. Easily store and access essential documents, including business formation papers,
      registered agent documents, legal notifications, state communications, and mail. Compatible with both iOS and Android.`,
      mobileAccessCopyText2: 'Questions? Reach out to our team anytime.',
    }
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'username',
      'doesUsernameExit',
      'usernameToken',
    ]),
    backgroundStyles() {
      return {
        'toggle-on': this.mobileAccessChoice,
        'toggle-off': !this.mobileAccessChoice,
      }
    },
    indicatorStyles() {
      return { transform: this.mobileAccessChoice ? 'translateX(14px)' : 'translateX(0)' }
    },
    androidTokenLink() {
      if (this.mobileAppType === 'corporate-tools') {
        return this.androidCTTokenLink
      } else {
        return this.androidPhoneTokenLink
      }
    },
    iosTokenLink() {
      if (this.mobileAppType === 'corporate-tools') {
        return this.iOSCTTokenLink
      } else {
        return this.iOSPhoneTokenLink
      }
    },
  },
  watch: {
    newUsername(value) {
      if (value?.length < 6 || value?.length > 32) {
        this.suggestions = []
        this.isValid = false
      } else {
        this.usernameMakeSuggestion()
      }
    },
  },
  async mounted() {
    // If we don't have a username, refresh the account data to make sure we pull one if it exists.
    if (!this.username) {
      await this.loadAccount()
    }

    this.loading = false

    if (this.username) {
      this.newUsername = this.username
      this.mobileAccessChoice = true
      this.usernameExist = true
    }

    setTimeout(() => {
      this.newUsername = this.username
      if (this.username != null ) {
        this.mobileAccessChoice = true
        this.usernameExist = true
      }
    }, 2000)
  },
  methods: {
    ...mapActions('account', [
      'createPersonUsername',
      'checkIfUsernameExists',
      'updatePersonUsername',
      'deletePersonUsername',
      'loadAccount',
    ]),
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    toggle() {
      if (this.mobileAccessChoice && this.username !== null) {
        if (confirm("Are you sure that you want to delete your username?")) {
          this.mobileAccessChoice = false
          this.usernameExist = false
          this.deleteUsername()
        }
      } else {
        this.mobileAccessChoice = !this.mobileAccessChoice
      }
    },
    canCreateOrUpdate() {
      this.toUpdateUsername ? this.updateUsername() : this.createUsername()
    },
    editClicked(e) {
      e.stopPropagation()
      this.newUsername = ""
      this.usernameExist = false
      this.toUpdateUsername = true
    },
    makeUsernameSuggestions() {
      let email = this.account.person_emails[0].email_address.split("@")[0]
      do
        this.suggestions.push(`${email}_${Math.floor(Math.random()*(999-100+1)+100)}`)
      while (this.suggestions.length <= 3)
    },
    async usernameMakeSuggestion() {
      try {
        await this.checkIfUsernameExists({ username: this.newUsername })
        if (this.doesUsernameExit) {
          this.isValid = false
          this.makeUsernameSuggestions()
        } else {
          this.suggestions = []
          this.isValid = true
        }
      } catch(e) {
        this.makeToast('danger', 'Failure', 'There was an issue, please try again')
      }
    },
    async createUsername() {
      try {
        await this.createPersonUsername({ username: this.newUsername, personId: this.account.id })
        this.makeToast('primary', 'Success', 'Username Created!')
        this.usernameExist = true
      } catch {
        this.makeToast('danger', 'Failure', 'The username could not be created. Please try again.')
      }
    },
    async updateUsername() {
      try {
        await this.updatePersonUsername({ username: this.username, newUsername: this.newUsername, personId: this.account.id })
        this.makeToast('primary', 'Success', 'Username Created!')
        this.toUpdateUsername = false
        this.usernameExist = true
      } catch {
        this.makeToast('danger', 'Failure', 'The username could not be created.')
      }
    },
    async deleteUsername() {
      try {
        await this.deletePersonUsername({ username: this.username, personId: this.account.id })
        this.makeToast('primary', 'Success', 'Username Deleted!')
      } catch {
        this.makeToast('danger', 'Failure', 'The username could not be deleted.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.masthead-title ::v-deep div > div.col-sm-12.pb-2.col-md-6 {
  flex: none;
  max-width: 100%;
}
.flex-container {
  display: flex;
  @media (max-width: 768px) {
    display: unset;
  }

  .flex-child {
    flex: 1;
  }

  .inner-left {
    max-width: 50%;
    @media (max-width: 768px) {
      max-width: 100%;
    }

    .copy {
      font-size: 14px;
    }
    .toggle-button {
      .toggle-on{
        background-color: rgb(95,188,186);
      }

      .toggle-off{
        background-color: #c2c2c2;
      }

      .toggleIsDisabled {
        height: 0px;
        overflow: visible;
        pointer-events: none;
        background:none !important;
      }

      .toggle-wrapper {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 32px;
        height: 18px;
        border-radius: 9999px;
        transform: scale(1.6);
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 1.5em;
      }

      .toggle-wrapper:focus {
        outline: 0;
      }

      .toggle-background {
        display: inline-block;
        border-radius: 9999px;
        height: 100%;
        width: 100%;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: background-color .4s ease;
      }

      .toggle-indicator {
        position: absolute;
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
        background-color: #ffffff;
        border-radius: 9999px;
        box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform .4s ease;
      }

      .modal-toggle-indicator {
        position: absolute;
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: -4px;
        background-color: #ffffff;
        border-radius: 9999px;
        box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform .4s ease;
      }

      p {
        display: inline;
        font-size: 12px;
      }
    }
  }
  .inner-right {
    border-radius: 3%;
    box-shadow: 0 0 3px #ccc, 0px 3px .2em #ccc;
    height: 100%;
    max-width: 50%;
    margin: 60px 30px 30px 30px;

    @media (max-width: 768px) {
      height: 65%;
      max-width: 100%;
    }

    .inner-content {
      padding: 60px;

      &-title {
        font-size: 20px;
        margin-bottom: 0;
      }
      &-sub-title-content {

        &-label {
          font-size: 12px;
          font-weight: bold;
          margin-top: 16px;
          margin-bottom: 12px;
        }

        #username-input {
          min-height: 40px !important;
          background-color: rgb(235,235,235);
          color: black;
          border: none;
        }

        .confirmButton {
          background-color: rgb(95,188,186);
          border: none;
          border-radius: 5px;
          margin-top: 30px;
        }

        .errorMessage {
          font-size: 10px;
          color: red;

        }
      }
      .username-state {
        margin-top: 24px;
        &-title {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        &-suggestions {
          margin: 0px;
          padding-left: 6px;
          font-size: 12px;
        }
      }
      &-display-username {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;

        #username-highlight {
          color: rgb(95,188,186);
          font-weight: bold;

          a:link {
            text-decoration: none;
            color: rgb(95,188,186);
          }

          a:visited {
            text-decoration: none;
            color: rgb(95,188,186);
          }

          a:hover {
            text-decoration: none;
            color: rgb(95,188,186);
          }

          a:active {
            text-decoration: none;
            color: rgb(95,188,186);
          }
        }
      }
      .qrcode {
        margin-top: 30px;
      }
    }
  }
}
.modal-text-size {
  font-size: 14px;
}

</style>
